var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "wrapper" }, [
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("h5", [_vm._v(_vm._s(_vm.title))]),
        _vm._v(" "),
        _c("p", [_vm._v("\n      " + _vm._s(_vm.message) + "\n    ")]),
        _vm._v(" "),
        _c(
          "b-button",
          {
            attrs: { size: "sm", variant: "lisaweb" },
            on: { click: _vm.action },
          },
          [_vm._v("OK")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }